<template>
  <v-app>
    <PcCustomLogin v-if="mobileDevice === 'pcDevice'"></PcCustomLogin>
    <MobileCustomLogin
      v-if="mobileDevice === 'mobileDevice'"
    ></MobileCustomLogin>
  </v-app>
</template>

<script>
import { isMobile } from "@/util/util";
import PcCustomLogin from "./component/PcCustomLogin";
import MobileCustomLogin from "./component/MobileCustomLogin";

export default {
  name: "CustomLogin",
  data() {
    return {
      mobileDevice: null
    };
  },
  async created() {
    if (await isMobile()) {
      this.mobileDevice = "mobileDevice";
    } else {
      this.mobileDevice = "pcDevice";
    }
  },
  components: {
    PcCustomLogin,
    MobileCustomLogin
  }
};
</script>
